import {
  DatabaseTransaction,
  RequestCreateTransactionRequest,
  TransactionApi,
  DepositApi,
} from '../sdk';

import {
  CREATE_TRANSACTION,
  GET_ONE_TX,
  GET_TRANSACTIONS,
  TX_APPROVE_OR_REJECT,
} from './constants';

import { config, getBaseOptions } from './axios';
import { GenericAbortSignal } from 'axios';

// ACTION CREATORS
const newTransaction = (data: DatabaseTransaction) => ({ type: CREATE_TRANSACTION, data });
const setTransactions = (data: DatabaseTransaction[]) => ({ type: GET_TRANSACTIONS, data });

export const createNewTransaction = (data: RequestCreateTransactionRequest) => async (dispatch: any) => {
  data.amount = data.amount.toString();

  const res = await new TransactionApi(config).v1DashboardTransactionsCreatePost(data, { ...getBaseOptions() });
  const output = res.data;

  await dispatch(newTransaction(output));
  return output;
};

export const getDeposits = async (signal?: GenericAbortSignal) => {
  const res = await new DepositApi(config).v1DashboardDepositsGet({ ...getBaseOptions(), signal });
  return res.data;
};

export const getTransactionsWithDispatch = (signal?: GenericAbortSignal) => async (dispatch: any) => {
  const transactions = await getTransactions(signal);
  await dispatch(setTransactions(transactions));
  return transactions;
}

export const getTransactions = async (signal?: GenericAbortSignal) => {
  const res = await new TransactionApi(config).v1DashboardTransactionsGet({ ...getBaseOptions(), signal });
  return res.data;
};

export const getTransactionStatus = async (txId: string) => {
  const res = await new TransactionApi(config).v1DashboardTransactionsStatusTxIdGet(txId, { ...getBaseOptions() });
  return res.data;
};

export const handleTxApprovalRejection = async (action: 'approve' | 'reject', txId: string) => {
  switch (action) {
    case 'approve':
      await new TransactionApi(config).v1DashboardTransactionsApproveTxIdPost(txId, { ...getBaseOptions() });
      break;
    case 'reject':
      await new TransactionApi(config).v1DashboardTransactionsRejectTxIdPost(txId, { ...getBaseOptions() });
      break;
    default:
      throw new Error('Action invalid: ' + action);
  }

}

// REDUCER
const transactionReducer = (state = {}, action: { type: string, data: any, walletId: any, network: string }) => {
  const newState = { ...state } as any;
  switch (action.type) {
    case CREATE_TRANSACTION: {
      return newState;
    }
    case GET_ONE_TX: {
      if (action.data) newState[action.data.txId] = action.data;
      return newState;
    }
    case GET_TRANSACTIONS: {
      if (action.data) action.data.forEach((tx: any) => (newState[tx.txId] = tx));
      return newState;
    }
    case TX_APPROVE_OR_REJECT: {
      return newState;
    }
    default:
      return state;
  }
};

export default transactionReducer;
