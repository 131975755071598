/* tslint:disable */
/* eslint-disable */
/**
 * Etana Digital API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface DatabaseApproval
 */
export interface DatabaseApproval {
    /**
     * 
     * @type {string}
     * @memberof DatabaseApproval
     */
    'approvedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseApproval
     */
    'status'?: DatabaseApprovalStatusEnum;
}

export const DatabaseApprovalStatusEnum = {
    Pending: 'pending',
    Rejected: 'rejected',
    Approved: 'approved',
    Cancelled: 'cancelled'
} as const;

export type DatabaseApprovalStatusEnum = typeof DatabaseApprovalStatusEnum[keyof typeof DatabaseApprovalStatusEnum];

/**
 * 
 * @export
 * @interface DatabaseDeposit
 */
export interface DatabaseDeposit {
    /**
     * 
     * @type {string}
     * @memberof DatabaseDeposit
     */
    'amount': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseDeposit
     */
    'asset': string;
    /**
     * 
     * @type {number}
     * @memberof DatabaseDeposit
     */
    'blockNumber': number;
    /**
     * 
     * @type {string}
     * @memberof DatabaseDeposit
     */
    'destinationAddress'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DatabaseDeposit
     */
    'destinationAddresses'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DatabaseDeposit
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseDeposit
     */
    'network': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseDeposit
     */
    'orgId': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseDeposit
     */
    'originAddress'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DatabaseDeposit
     */
    'originAddresses'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DatabaseDeposit
     */
    'owner': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseDeposit
     */
    'seenAt': string;
    /**
     * 
     * @type {boolean}
     * @memberof DatabaseDeposit
     */
    'success': boolean;
    /**
     * 
     * @type {string}
     * @memberof DatabaseDeposit
     */
    'txHash': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseDeposit
     */
    'walletId': string;
}
/**
 * 
 * @export
 * @interface DatabaseTransaction
 */
export interface DatabaseTransaction {
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'amount': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'amountV2': string;
    /**
     * 
     * @type {DatabaseApproval}
     * @memberof DatabaseTransaction
     */
    'approval': DatabaseApproval;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'asset': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'dateCreated': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'dateProcessed'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'dateVerified'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'destinationAddress': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'direction': DatabaseTransactionDirectionEnum;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'fee'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'network': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'orgId': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'originAddress': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'owner': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'requestingManager': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'txHash'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'txId': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'txProcessingStatus': DatabaseTransactionTxProcessingStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof DatabaseTransaction
     */
    'walletId': string;
}

export const DatabaseTransactionDirectionEnum = {
    Withdraw: 'withdraw'
} as const;

export type DatabaseTransactionDirectionEnum = typeof DatabaseTransactionDirectionEnum[keyof typeof DatabaseTransactionDirectionEnum];
export const DatabaseTransactionTxProcessingStatusEnum = {
    Pending: 'pending',
    Cancelled: 'cancelled',
    Processing: 'processing',
    Submitted: 'submitted',
    Success: 'success',
    Failure: 'failure'
} as const;

export type DatabaseTransactionTxProcessingStatusEnum = typeof DatabaseTransactionTxProcessingStatusEnum[keyof typeof DatabaseTransactionTxProcessingStatusEnum];

/**
 * 
 * @export
 * @interface DatabaseUser
 */
export interface DatabaseUser {
    /**
     * 
     * @type {string}
     * @memberof DatabaseUser
     */
    'apiKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseUser
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseUser
     */
    'familyName': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseUser
     */
    'givenName': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DatabaseUser
     */
    'groups': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DatabaseUser
     */
    'orgId': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseUser
     */
    'sub': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseUser
     */
    'webhookKey': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseUser
     */
    'webhookUrl'?: string;
}
/**
 * 
 * @export
 * @interface DatabaseWallet
 */
export interface DatabaseWallet {
    /**
     * 
     * @type {{ [key: string]: OpenapiAccount; }}
     * @memberof DatabaseWallet
     */
    'accounts'?: { [key: string]: OpenapiAccount; };
    /**
     * 
     * @type {boolean}
     * @memberof DatabaseWallet
     */
    'active'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof DatabaseWallet
     */
    'managers': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DatabaseWallet
     */
    'orgId': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseWallet
     */
    'owner': string;
    /**
     * 
     * @type {string}
     * @memberof DatabaseWallet
     */
    'walletId': string;
}
/**
 * 
 * @export
 * @interface HandleUpdatedUser
 */
export interface HandleUpdatedUser {
    /**
     * 
     * @type {DatabaseUser}
     * @memberof HandleUpdatedUser
     */
    'data'?: DatabaseUser;
}
/**
 * 
 * @export
 * @interface OpenapiAccount
 */
export interface OpenapiAccount {
    /**
     * 
     * @type {boolean}
     * @memberof OpenapiAccount
     */
    'active'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OpenapiAccount
     */
    'address'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OpenapiAccount
     */
    'assets'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OpenapiAccount
     */
    'token'?: string;
}
/**
 * 
 * @export
 * @interface OpenapiBlockchainAssetsData
 */
export interface OpenapiBlockchainAssetsData {
    /**
     * 
     * @type {Array<OpenapiBlockchainAssetsNetworkData>}
     * @memberof OpenapiBlockchainAssetsData
     */
    'data'?: Array<OpenapiBlockchainAssetsNetworkData>;
    /**
     * 
     * @type {string}
     * @memberof OpenapiBlockchainAssetsData
     */
    'network'?: string;
}
/**
 * 
 * @export
 * @interface OpenapiBlockchainAssetsNetworkData
 */
export interface OpenapiBlockchainAssetsNetworkData {
    /**
     * 
     * @type {string}
     * @memberof OpenapiBlockchainAssetsNetworkData
     */
    'asset'?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenapiBlockchainAssetsNetworkData
     */
    'contractAddress'?: string;
    /**
     * 
     * @type {number}
     * @memberof OpenapiBlockchainAssetsNetworkData
     */
    'decimals'?: number;
}
/**
 * 
 * @export
 * @interface OpenapiOpenapiWatchtowerCoreBalanceResponse
 */
export interface OpenapiOpenapiWatchtowerCoreBalanceResponse {
    /**
     * 
     * @type {string}
     * @memberof OpenapiOpenapiWatchtowerCoreBalanceResponse
     */
    'address'?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenapiOpenapiWatchtowerCoreBalanceResponse
     */
    'asset'?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenapiOpenapiWatchtowerCoreBalanceResponse
     */
    'balance'?: string;
    /**
     * 
     * @type {number}
     * @memberof OpenapiOpenapiWatchtowerCoreBalanceResponse
     */
    'decimals'?: number;
    /**
     * 
     * @type {string}
     * @memberof OpenapiOpenapiWatchtowerCoreBalanceResponse
     */
    'network'?: string;
}
/**
 * 
 * @export
 * @interface OpenapiRoutesBalanceOnWalletResponse
 */
export interface OpenapiRoutesBalanceOnWalletResponse {
    /**
     * 
     * @type {Array<OpenapiOpenapiWatchtowerCoreBalanceResponse>}
     * @memberof OpenapiRoutesBalanceOnWalletResponse
     */
    'data'?: Array<OpenapiOpenapiWatchtowerCoreBalanceResponse>;
    /**
     * 
     * @type {string}
     * @memberof OpenapiRoutesBalanceOnWalletResponse
     */
    'ts'?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenapiRoutesBalanceOnWalletResponse
     */
    'walletId'?: string;
}
/**
 * 
 * @export
 * @interface OpenapiWalletApiATransactionSubmitResponse
 */
export interface OpenapiWalletApiATransactionSubmitResponse {
    /**
     * 
     * @type {string}
     * @memberof OpenapiWalletApiATransactionSubmitResponse
     */
    'amount'?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenapiWalletApiATransactionSubmitResponse
     */
    'network'?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenapiWalletApiATransactionSubmitResponse
     */
    'to'?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenapiWalletApiATransactionSubmitResponse
     */
    'txHash'?: string;
    /**
     * 
     * @type {string}
     * @memberof OpenapiWalletApiATransactionSubmitResponse
     */
    'walletId'?: string;
}
/**
 * 
 * @export
 * @interface RequestAddAssetRequest
 */
export interface RequestAddAssetRequest {
    /**
     * 
     * @type {Array<RequestAssetData>}
     * @memberof RequestAddAssetRequest
     */
    'assets': Array<RequestAssetData>;
    /**
     * 
     * @type {string}
     * @memberof RequestAddAssetRequest
     */
    'walletId': string;
}
/**
 * 
 * @export
 * @interface RequestAddOrRemoveManagersRequest
 */
export interface RequestAddOrRemoveManagersRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof RequestAddOrRemoveManagersRequest
     */
    'managers': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RequestAddOrRemoveManagersRequest
     */
    'walletId': string;
}
/**
 * 
 * @export
 * @interface RequestAssetData
 */
export interface RequestAssetData {
    /**
     * 
     * @type {string}
     * @memberof RequestAssetData
     */
    'asset': string;
    /**
     * 
     * @type {string}
     * @memberof RequestAssetData
     */
    'network': string;
}
/**
 * 
 * @export
 * @interface RequestCreateTransactionRequest
 */
export interface RequestCreateTransactionRequest {
    /**
     * 
     * @type {string}
     * @memberof RequestCreateTransactionRequest
     */
    'amount': string;
    /**
     * 
     * @type {string}
     * @memberof RequestCreateTransactionRequest
     */
    'asset'?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestCreateTransactionRequest
     */
    'destinationAddress': string;
    /**
     * 
     * @type {string}
     * @memberof RequestCreateTransactionRequest
     */
    'network': string;
    /**
     * 
     * @type {string}
     * @memberof RequestCreateTransactionRequest
     */
    'walletId': string;
}
/**
 * 
 * @export
 * @interface RequestCreateWallet
 */
export interface RequestCreateWallet {
    /**
     * 
     * @type {Array<RequestAssetData>}
     * @memberof RequestCreateWallet
     */
    'assets'?: Array<RequestAssetData>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RequestCreateWallet
     */
    'managers': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RequestCreateWallet
     */
    'orgId': string;
    /**
     * 
     * @type {string}
     * @memberof RequestCreateWallet
     */
    'owner': string;
}
/**
 * 
 * @export
 * @interface RequestHideAssetRequest
 */
export interface RequestHideAssetRequest {
    /**
     * 
     * @type {string}
     * @memberof RequestHideAssetRequest
     */
    'asset': string;
    /**
     * 
     * @type {string}
     * @memberof RequestHideAssetRequest
     */
    'network': string;
    /**
     * 
     * @type {string}
     * @memberof RequestHideAssetRequest
     */
    'walletId': string;
}
/**
 * 
 * @export
 * @interface RequestUserUpdate
 */
export interface RequestUserUpdate {
    /**
     * 
     * @type {string}
     * @memberof RequestUserUpdate
     */
    'familyName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestUserUpdate
     */
    'givenName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestUserUpdate
     */
    'webhookUrl'?: string;
}
/**
 * 
 * @export
 * @interface RequestUserUpdateRequest
 */
export interface RequestUserUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof RequestUserUpdateRequest
     */
    'email': string;
    /**
     * 
     * @type {RequestUserUpdate}
     * @memberof RequestUserUpdateRequest
     */
    'update'?: RequestUserUpdate;
}

/**
 * AssetApi - axios parameter creator
 * @export
 */
export const AssetApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add an asset to a wallet
         * @summary Add Asset
         * @param {RequestAddAssetRequest} req add asset request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardWalletsAssetsAddPost: async (req: RequestAddAssetRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('v1DashboardWalletsAssetsAddPost', 'req', req)
            const localVarPath = `/v1/dashboard/wallets/assets/add`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get available assets
         * @summary Get assets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardWalletsAssetsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dashboard/wallets/assets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Hide an asset from a wallet
         * @summary Hide Asset
         * @param {RequestHideAssetRequest} req hide asset request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardWalletsAssetsHidePost: async (req: RequestHideAssetRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('v1DashboardWalletsAssetsHidePost', 'req', req)
            const localVarPath = `/v1/dashboard/wallets/assets/hide`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AssetApi - functional programming interface
 * @export
 */
export const AssetApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AssetApiAxiosParamCreator(configuration)
    return {
        /**
         * Add an asset to a wallet
         * @summary Add Asset
         * @param {RequestAddAssetRequest} req add asset request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DashboardWalletsAssetsAddPost(req: RequestAddAssetRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatabaseWallet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DashboardWalletsAssetsAddPost(req, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetApi.v1DashboardWalletsAssetsAddPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get available assets
         * @summary Get assets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DashboardWalletsAssetsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OpenapiBlockchainAssetsData>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DashboardWalletsAssetsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetApi.v1DashboardWalletsAssetsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Hide an asset from a wallet
         * @summary Hide Asset
         * @param {RequestHideAssetRequest} req hide asset request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DashboardWalletsAssetsHidePost(req: RequestHideAssetRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatabaseWallet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DashboardWalletsAssetsHidePost(req, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AssetApi.v1DashboardWalletsAssetsHidePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AssetApi - factory interface
 * @export
 */
export const AssetApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AssetApiFp(configuration)
    return {
        /**
         * Add an asset to a wallet
         * @summary Add Asset
         * @param {RequestAddAssetRequest} req add asset request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardWalletsAssetsAddPost(req: RequestAddAssetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DatabaseWallet> {
            return localVarFp.v1DashboardWalletsAssetsAddPost(req, options).then((request) => request(axios, basePath));
        },
        /**
         * Get available assets
         * @summary Get assets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardWalletsAssetsGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<OpenapiBlockchainAssetsData>> {
            return localVarFp.v1DashboardWalletsAssetsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Hide an asset from a wallet
         * @summary Hide Asset
         * @param {RequestHideAssetRequest} req hide asset request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardWalletsAssetsHidePost(req: RequestHideAssetRequest, options?: RawAxiosRequestConfig): AxiosPromise<DatabaseWallet> {
            return localVarFp.v1DashboardWalletsAssetsHidePost(req, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AssetApi - object-oriented interface
 * @export
 * @class AssetApi
 * @extends {BaseAPI}
 */
export class AssetApi extends BaseAPI {
    /**
     * Add an asset to a wallet
     * @summary Add Asset
     * @param {RequestAddAssetRequest} req add asset request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public v1DashboardWalletsAssetsAddPost(req: RequestAddAssetRequest, options?: RawAxiosRequestConfig) {
        return AssetApiFp(this.configuration).v1DashboardWalletsAssetsAddPost(req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get available assets
     * @summary Get assets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public v1DashboardWalletsAssetsGet(options?: RawAxiosRequestConfig) {
        return AssetApiFp(this.configuration).v1DashboardWalletsAssetsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Hide an asset from a wallet
     * @summary Hide Asset
     * @param {RequestHideAssetRequest} req hide asset request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    public v1DashboardWalletsAssetsHidePost(req: RequestHideAssetRequest, options?: RawAxiosRequestConfig) {
        return AssetApiFp(this.configuration).v1DashboardWalletsAssetsHidePost(req, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * BalanceApi - axios parameter creator
 * @export
 */
export const BalanceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieve the balance based on wallet ID.
         * @summary Get Balance
         * @param {string} id Wallet ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardWalletsBalancesIdPost: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1DashboardWalletsBalancesIdPost', 'id', id)
            const localVarPath = `/v1/dashboard/wallets/balances/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BalanceApi - functional programming interface
 * @export
 */
export const BalanceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BalanceApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieve the balance based on wallet ID.
         * @summary Get Balance
         * @param {string} id Wallet ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DashboardWalletsBalancesIdPost(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpenapiRoutesBalanceOnWalletResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DashboardWalletsBalancesIdPost(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['BalanceApi.v1DashboardWalletsBalancesIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * BalanceApi - factory interface
 * @export
 */
export const BalanceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BalanceApiFp(configuration)
    return {
        /**
         * Retrieve the balance based on wallet ID.
         * @summary Get Balance
         * @param {string} id Wallet ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardWalletsBalancesIdPost(id: string, options?: RawAxiosRequestConfig): AxiosPromise<OpenapiRoutesBalanceOnWalletResponse> {
            return localVarFp.v1DashboardWalletsBalancesIdPost(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BalanceApi - object-oriented interface
 * @export
 * @class BalanceApi
 * @extends {BaseAPI}
 */
export class BalanceApi extends BaseAPI {
    /**
     * Retrieve the balance based on wallet ID.
     * @summary Get Balance
     * @param {string} id Wallet ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalanceApi
     */
    public v1DashboardWalletsBalancesIdPost(id: string, options?: RawAxiosRequestConfig) {
        return BalanceApiFp(this.configuration).v1DashboardWalletsBalancesIdPost(id, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DepositApi - axios parameter creator
 * @export
 */
export const DepositApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get all deposits given the user role
         * @summary Get deposits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardDepositsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dashboard/deposits`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepositApi - functional programming interface
 * @export
 */
export const DepositApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DepositApiAxiosParamCreator(configuration)
    return {
        /**
         * Get all deposits given the user role
         * @summary Get deposits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DashboardDepositsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DatabaseDeposit>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DashboardDepositsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DepositApi.v1DashboardDepositsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DepositApi - factory interface
 * @export
 */
export const DepositApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DepositApiFp(configuration)
    return {
        /**
         * Get all deposits given the user role
         * @summary Get deposits
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardDepositsGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<DatabaseDeposit>> {
            return localVarFp.v1DashboardDepositsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DepositApi - object-oriented interface
 * @export
 * @class DepositApi
 * @extends {BaseAPI}
 */
export class DepositApi extends BaseAPI {
    /**
     * Get all deposits given the user role
     * @summary Get deposits
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepositApi
     */
    public v1DashboardDepositsGet(options?: RawAxiosRequestConfig) {
        return DepositApiFp(this.configuration).v1DashboardDepositsGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ManagerApi - axios parameter creator
 * @export
 */
export const ManagerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete managers from a wallet
         * @summary Delete Managers
         * @param {RequestAddOrRemoveManagersRequest} req add managers request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardWalletsManagersDelete: async (req: RequestAddOrRemoveManagersRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('v1DashboardWalletsManagersDelete', 'req', req)
            const localVarPath = `/v1/dashboard/wallets/managers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add managers to a wallet
         * @summary Add Managers
         * @param {RequestAddOrRemoveManagersRequest} req add managers request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardWalletsManagersPost: async (req: RequestAddOrRemoveManagersRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('v1DashboardWalletsManagersPost', 'req', req)
            const localVarPath = `/v1/dashboard/wallets/managers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManagerApi - functional programming interface
 * @export
 */
export const ManagerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManagerApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete managers from a wallet
         * @summary Delete Managers
         * @param {RequestAddOrRemoveManagersRequest} req add managers request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DashboardWalletsManagersDelete(req: RequestAddOrRemoveManagersRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatabaseWallet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DashboardWalletsManagersDelete(req, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManagerApi.v1DashboardWalletsManagersDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Add managers to a wallet
         * @summary Add Managers
         * @param {RequestAddOrRemoveManagersRequest} req add managers request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DashboardWalletsManagersPost(req: RequestAddOrRemoveManagersRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatabaseWallet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DashboardWalletsManagersPost(req, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ManagerApi.v1DashboardWalletsManagersPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ManagerApi - factory interface
 * @export
 */
export const ManagerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManagerApiFp(configuration)
    return {
        /**
         * Delete managers from a wallet
         * @summary Delete Managers
         * @param {RequestAddOrRemoveManagersRequest} req add managers request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardWalletsManagersDelete(req: RequestAddOrRemoveManagersRequest, options?: RawAxiosRequestConfig): AxiosPromise<DatabaseWallet> {
            return localVarFp.v1DashboardWalletsManagersDelete(req, options).then((request) => request(axios, basePath));
        },
        /**
         * Add managers to a wallet
         * @summary Add Managers
         * @param {RequestAddOrRemoveManagersRequest} req add managers request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardWalletsManagersPost(req: RequestAddOrRemoveManagersRequest, options?: RawAxiosRequestConfig): AxiosPromise<DatabaseWallet> {
            return localVarFp.v1DashboardWalletsManagersPost(req, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ManagerApi - object-oriented interface
 * @export
 * @class ManagerApi
 * @extends {BaseAPI}
 */
export class ManagerApi extends BaseAPI {
    /**
     * Delete managers from a wallet
     * @summary Delete Managers
     * @param {RequestAddOrRemoveManagersRequest} req add managers request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagerApi
     */
    public v1DashboardWalletsManagersDelete(req: RequestAddOrRemoveManagersRequest, options?: RawAxiosRequestConfig) {
        return ManagerApiFp(this.configuration).v1DashboardWalletsManagersDelete(req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add managers to a wallet
     * @summary Add Managers
     * @param {RequestAddOrRemoveManagersRequest} req add managers request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManagerApi
     */
    public v1DashboardWalletsManagersPost(req: RequestAddOrRemoveManagersRequest, options?: RawAxiosRequestConfig) {
        return ManagerApiFp(this.configuration).v1DashboardWalletsManagersPost(req, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TransactionApi - axios parameter creator
 * @export
 */
export const TransactionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Approve a transaction
         * @summary Approve Transaction
         * @param {string} txId Transaction ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardTransactionsApproveTxIdPost: async (txId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'txId' is not null or undefined
            assertParamExists('v1DashboardTransactionsApproveTxIdPost', 'txId', txId)
            const localVarPath = `/v1/dashboard/transactions/approve/{txId}`
                .replace(`{${"txId"}}`, encodeURIComponent(String(txId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new transaction
         * @summary Create Transaction
         * @param {RequestCreateTransactionRequest} req create transaction request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardTransactionsCreatePost: async (req: RequestCreateTransactionRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('v1DashboardTransactionsCreatePost', 'req', req)
            const localVarPath = `/v1/dashboard/transactions/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all withdrawals given the user role
         * @summary Get withdrawals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardTransactionsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dashboard/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reject a transaction
         * @summary Reject Transaction
         * @param {string} txId Transaction ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardTransactionsRejectTxIdPost: async (txId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'txId' is not null or undefined
            assertParamExists('v1DashboardTransactionsRejectTxIdPost', 'txId', txId)
            const localVarPath = `/v1/dashboard/transactions/reject/{txId}`
                .replace(`{${"txId"}}`, encodeURIComponent(String(txId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a transaction status
         * @summary Get status
         * @param {string} txId Transaction ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardTransactionsStatusTxIdGet: async (txId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'txId' is not null or undefined
            assertParamExists('v1DashboardTransactionsStatusTxIdGet', 'txId', txId)
            const localVarPath = `/v1/dashboard/transactions/status/{txId}`
                .replace(`{${"txId"}}`, encodeURIComponent(String(txId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionApi - functional programming interface
 * @export
 */
export const TransactionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionApiAxiosParamCreator(configuration)
    return {
        /**
         * Approve a transaction
         * @summary Approve Transaction
         * @param {string} txId Transaction ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DashboardTransactionsApproveTxIdPost(txId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OpenapiWalletApiATransactionSubmitResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DashboardTransactionsApproveTxIdPost(txId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionApi.v1DashboardTransactionsApproveTxIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Creates a new transaction
         * @summary Create Transaction
         * @param {RequestCreateTransactionRequest} req create transaction request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DashboardTransactionsCreatePost(req: RequestCreateTransactionRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatabaseTransaction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DashboardTransactionsCreatePost(req, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionApi.v1DashboardTransactionsCreatePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all withdrawals given the user role
         * @summary Get withdrawals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DashboardTransactionsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DatabaseTransaction>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DashboardTransactionsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionApi.v1DashboardTransactionsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Reject a transaction
         * @summary Reject Transaction
         * @param {string} txId Transaction ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DashboardTransactionsRejectTxIdPost(txId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatabaseTransaction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DashboardTransactionsRejectTxIdPost(txId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionApi.v1DashboardTransactionsRejectTxIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a transaction status
         * @summary Get status
         * @param {string} txId Transaction ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DashboardTransactionsStatusTxIdGet(txId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatabaseTransaction>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DashboardTransactionsStatusTxIdGet(txId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TransactionApi.v1DashboardTransactionsStatusTxIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TransactionApi - factory interface
 * @export
 */
export const TransactionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionApiFp(configuration)
    return {
        /**
         * Approve a transaction
         * @summary Approve Transaction
         * @param {string} txId Transaction ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardTransactionsApproveTxIdPost(txId: string, options?: RawAxiosRequestConfig): AxiosPromise<OpenapiWalletApiATransactionSubmitResponse> {
            return localVarFp.v1DashboardTransactionsApproveTxIdPost(txId, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a new transaction
         * @summary Create Transaction
         * @param {RequestCreateTransactionRequest} req create transaction request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardTransactionsCreatePost(req: RequestCreateTransactionRequest, options?: RawAxiosRequestConfig): AxiosPromise<DatabaseTransaction> {
            return localVarFp.v1DashboardTransactionsCreatePost(req, options).then((request) => request(axios, basePath));
        },
        /**
         * Get all withdrawals given the user role
         * @summary Get withdrawals
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardTransactionsGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<DatabaseTransaction>> {
            return localVarFp.v1DashboardTransactionsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Reject a transaction
         * @summary Reject Transaction
         * @param {string} txId Transaction ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardTransactionsRejectTxIdPost(txId: string, options?: RawAxiosRequestConfig): AxiosPromise<DatabaseTransaction> {
            return localVarFp.v1DashboardTransactionsRejectTxIdPost(txId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a transaction status
         * @summary Get status
         * @param {string} txId Transaction ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardTransactionsStatusTxIdGet(txId: string, options?: RawAxiosRequestConfig): AxiosPromise<DatabaseTransaction> {
            return localVarFp.v1DashboardTransactionsStatusTxIdGet(txId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionApi - object-oriented interface
 * @export
 * @class TransactionApi
 * @extends {BaseAPI}
 */
export class TransactionApi extends BaseAPI {
    /**
     * Approve a transaction
     * @summary Approve Transaction
     * @param {string} txId Transaction ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public v1DashboardTransactionsApproveTxIdPost(txId: string, options?: RawAxiosRequestConfig) {
        return TransactionApiFp(this.configuration).v1DashboardTransactionsApproveTxIdPost(txId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a new transaction
     * @summary Create Transaction
     * @param {RequestCreateTransactionRequest} req create transaction request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public v1DashboardTransactionsCreatePost(req: RequestCreateTransactionRequest, options?: RawAxiosRequestConfig) {
        return TransactionApiFp(this.configuration).v1DashboardTransactionsCreatePost(req, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all withdrawals given the user role
     * @summary Get withdrawals
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public v1DashboardTransactionsGet(options?: RawAxiosRequestConfig) {
        return TransactionApiFp(this.configuration).v1DashboardTransactionsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Reject a transaction
     * @summary Reject Transaction
     * @param {string} txId Transaction ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public v1DashboardTransactionsRejectTxIdPost(txId: string, options?: RawAxiosRequestConfig) {
        return TransactionApiFp(this.configuration).v1DashboardTransactionsRejectTxIdPost(txId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a transaction status
     * @summary Get status
     * @param {string} txId Transaction ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public v1DashboardTransactionsStatusTxIdGet(txId: string, options?: RawAxiosRequestConfig) {
        return TransactionApiFp(this.configuration).v1DashboardTransactionsStatusTxIdGet(txId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * issues, rotates, or optionally deletes a user\'s API key
         * @summary Manage user api jey
         * @param {string} [_delete] true if this is a delete request
         * @param {string} [email] optional email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardUserApikeyPatch: async (_delete?: string, email?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dashboard/user/apikey`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (_delete !== undefined) {
                localVarQueryParameter['delete'] = _delete;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns current logged user
         * @summary Get current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardUserCurrentGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dashboard/user/current`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get all users given the user role
         * @summary Get users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardUserGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dashboard/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a user
         * @summary Update user
         * @param {RequestUserUpdateRequest} req update user request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardUserPatch: async (req: RequestUserUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('v1DashboardUserPatch', 'req', req)
            const localVarPath = `/v1/dashboard/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * issues, rotates, or optionally deletes a user\'s API key
         * @summary Manage user api jey
         * @param {string} [_delete] true if this is a delete request
         * @param {string} [email] optional email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DashboardUserApikeyPatch(_delete?: string, email?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HandleUpdatedUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DashboardUserApikeyPatch(_delete, email, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.v1DashboardUserApikeyPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Returns current logged user
         * @summary Get current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DashboardUserCurrentGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatabaseUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DashboardUserCurrentGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.v1DashboardUserCurrentGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get all users given the user role
         * @summary Get users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DashboardUserGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DatabaseUser>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DashboardUserGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.v1DashboardUserGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Update a user
         * @summary Update user
         * @param {RequestUserUpdateRequest} req update user request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DashboardUserPatch(req: RequestUserUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatabaseUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DashboardUserPatch(req, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.v1DashboardUserPatch']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * issues, rotates, or optionally deletes a user\'s API key
         * @summary Manage user api jey
         * @param {string} [_delete] true if this is a delete request
         * @param {string} [email] optional email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardUserApikeyPatch(_delete?: string, email?: string, options?: RawAxiosRequestConfig): AxiosPromise<HandleUpdatedUser> {
            return localVarFp.v1DashboardUserApikeyPatch(_delete, email, options).then((request) => request(axios, basePath));
        },
        /**
         * Returns current logged user
         * @summary Get current user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardUserCurrentGet(options?: RawAxiosRequestConfig): AxiosPromise<DatabaseUser> {
            return localVarFp.v1DashboardUserCurrentGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get all users given the user role
         * @summary Get users
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardUserGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<DatabaseUser>> {
            return localVarFp.v1DashboardUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Update a user
         * @summary Update user
         * @param {RequestUserUpdateRequest} req update user request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardUserPatch(req: RequestUserUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<DatabaseUser> {
            return localVarFp.v1DashboardUserPatch(req, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * issues, rotates, or optionally deletes a user\'s API key
     * @summary Manage user api jey
     * @param {string} [_delete] true if this is a delete request
     * @param {string} [email] optional email
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public v1DashboardUserApikeyPatch(_delete?: string, email?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).v1DashboardUserApikeyPatch(_delete, email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Returns current logged user
     * @summary Get current user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public v1DashboardUserCurrentGet(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).v1DashboardUserCurrentGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get all users given the user role
     * @summary Get users
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public v1DashboardUserGet(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).v1DashboardUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a user
     * @summary Update user
     * @param {RequestUserUpdateRequest} req update user request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public v1DashboardUserPatch(req: RequestUserUpdateRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).v1DashboardUserPatch(req, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WalletApi - axios parameter creator
 * @export
 */
export const WalletApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Enable a wallet
         * @summary Enable Wallet
         * @param {string} walletId Wallet ID
         * @param {string} action Action to be performed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardAdminWalletsWalletIdActionPost: async (walletId: string, action: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'walletId' is not null or undefined
            assertParamExists('v1DashboardAdminWalletsWalletIdActionPost', 'walletId', walletId)
            // verify required parameter 'action' is not null or undefined
            assertParamExists('v1DashboardAdminWalletsWalletIdActionPost', 'action', action)
            const localVarPath = `/v1/dashboard/admin/wallets/{walletId}/{action}`
                .replace(`{${"walletId"}}`, encodeURIComponent(String(walletId)))
                .replace(`{${"action"}}`, encodeURIComponent(String(action)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a wallets automatically filterd by user role
         * @summary Get Wallets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardWalletsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/v1/dashboard/wallets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get wallet field
         * @summary Get wallet field
         * @param {string} id Wallet ID
         * @param {string} field Field to be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardWalletsIdIdFieldGet: async (id: string, field: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1DashboardWalletsIdIdFieldGet', 'id', id)
            // verify required parameter 'field' is not null or undefined
            assertParamExists('v1DashboardWalletsIdIdFieldGet', 'field', field)
            const localVarPath = `/v1/dashboard/wallets/id/{id}/{field}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"field"}}`, encodeURIComponent(String(field)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get an wallet by id
         * @summary Get Wallet
         * @param {string} id Wallet ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardWalletsIdIdGet: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('v1DashboardWalletsIdIdGet', 'id', id)
            const localVarPath = `/v1/dashboard/wallets/id/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a new wallet
         * @summary Create Wallet
         * @param {RequestCreateWallet} req create wallet request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardWalletsPost: async (req: RequestCreateWallet, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'req' is not null or undefined
            assertParamExists('v1DashboardWalletsPost', 'req', req)
            const localVarPath = `/v1/dashboard/wallets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(req, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WalletApi - functional programming interface
 * @export
 */
export const WalletApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WalletApiAxiosParamCreator(configuration)
    return {
        /**
         * Enable a wallet
         * @summary Enable Wallet
         * @param {string} walletId Wallet ID
         * @param {string} action Action to be performed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DashboardAdminWalletsWalletIdActionPost(walletId: string, action: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatabaseWallet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DashboardAdminWalletsWalletIdActionPost(walletId, action, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletApi.v1DashboardAdminWalletsWalletIdActionPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get a wallets automatically filterd by user role
         * @summary Get Wallets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DashboardWalletsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DatabaseWallet>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DashboardWalletsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletApi.v1DashboardWalletsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get wallet field
         * @summary Get wallet field
         * @param {string} id Wallet ID
         * @param {string} field Field to be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DashboardWalletsIdIdFieldGet(id: string, field: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatabaseWallet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DashboardWalletsIdIdFieldGet(id, field, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletApi.v1DashboardWalletsIdIdFieldGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Get an wallet by id
         * @summary Get Wallet
         * @param {string} id Wallet ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DashboardWalletsIdIdGet(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatabaseWallet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DashboardWalletsIdIdGet(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletApi.v1DashboardWalletsIdIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Create a new wallet
         * @summary Create Wallet
         * @param {RequestCreateWallet} req create wallet request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v1DashboardWalletsPost(req: RequestCreateWallet, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DatabaseWallet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v1DashboardWalletsPost(req, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WalletApi.v1DashboardWalletsPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WalletApi - factory interface
 * @export
 */
export const WalletApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WalletApiFp(configuration)
    return {
        /**
         * Enable a wallet
         * @summary Enable Wallet
         * @param {string} walletId Wallet ID
         * @param {string} action Action to be performed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardAdminWalletsWalletIdActionPost(walletId: string, action: string, options?: RawAxiosRequestConfig): AxiosPromise<DatabaseWallet> {
            return localVarFp.v1DashboardAdminWalletsWalletIdActionPost(walletId, action, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a wallets automatically filterd by user role
         * @summary Get Wallets
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardWalletsGet(options?: RawAxiosRequestConfig): AxiosPromise<Array<DatabaseWallet>> {
            return localVarFp.v1DashboardWalletsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Get wallet field
         * @summary Get wallet field
         * @param {string} id Wallet ID
         * @param {string} field Field to be retrieved
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardWalletsIdIdFieldGet(id: string, field: string, options?: RawAxiosRequestConfig): AxiosPromise<DatabaseWallet> {
            return localVarFp.v1DashboardWalletsIdIdFieldGet(id, field, options).then((request) => request(axios, basePath));
        },
        /**
         * Get an wallet by id
         * @summary Get Wallet
         * @param {string} id Wallet ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardWalletsIdIdGet(id: string, options?: RawAxiosRequestConfig): AxiosPromise<DatabaseWallet> {
            return localVarFp.v1DashboardWalletsIdIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a new wallet
         * @summary Create Wallet
         * @param {RequestCreateWallet} req create wallet request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v1DashboardWalletsPost(req: RequestCreateWallet, options?: RawAxiosRequestConfig): AxiosPromise<DatabaseWallet> {
            return localVarFp.v1DashboardWalletsPost(req, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WalletApi - object-oriented interface
 * @export
 * @class WalletApi
 * @extends {BaseAPI}
 */
export class WalletApi extends BaseAPI {
    /**
     * Enable a wallet
     * @summary Enable Wallet
     * @param {string} walletId Wallet ID
     * @param {string} action Action to be performed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public v1DashboardAdminWalletsWalletIdActionPost(walletId: string, action: string, options?: RawAxiosRequestConfig) {
        return WalletApiFp(this.configuration).v1DashboardAdminWalletsWalletIdActionPost(walletId, action, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a wallets automatically filterd by user role
     * @summary Get Wallets
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public v1DashboardWalletsGet(options?: RawAxiosRequestConfig) {
        return WalletApiFp(this.configuration).v1DashboardWalletsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get wallet field
     * @summary Get wallet field
     * @param {string} id Wallet ID
     * @param {string} field Field to be retrieved
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public v1DashboardWalletsIdIdFieldGet(id: string, field: string, options?: RawAxiosRequestConfig) {
        return WalletApiFp(this.configuration).v1DashboardWalletsIdIdFieldGet(id, field, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get an wallet by id
     * @summary Get Wallet
     * @param {string} id Wallet ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public v1DashboardWalletsIdIdGet(id: string, options?: RawAxiosRequestConfig) {
        return WalletApiFp(this.configuration).v1DashboardWalletsIdIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a new wallet
     * @summary Create Wallet
     * @param {RequestCreateWallet} req create wallet request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletApi
     */
    public v1DashboardWalletsPost(req: RequestCreateWallet, options?: RawAxiosRequestConfig) {
        return WalletApiFp(this.configuration).v1DashboardWalletsPost(req, options).then((request) => request(this.axios, this.basePath));
    }
}



