import Cookies from 'universal-cookie';
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  type RawAxiosRequestConfig,
} from 'axios';

import { urls } from '../config';
import { Configuration } from '../sdk';

export const config = new Configuration({
  basePath: urls.baseUrl,
});

export const getBaseOptions = (): RawAxiosRequestConfig => {
  const cookies = new Cookies();
  return {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${cookies.get('jwt_token')}`,
    },
  };
};

export const handleApiRequest = async <T>(request: () => Promise<AxiosResponse<T>>): Promise<T> => {
  try {
    const res = await request();
    return res.data;
  } catch (e) {
    if (e instanceof AxiosError) {
      throw e.response.data;
    }
    throw e;
  }
};

async function axiosRequest(
  url: string,
  options: Partial<AxiosRequestConfig> | null = null,
): Promise<AxiosResponse<any, any>> {
  const cookies = new Cookies();
  options ||= {};
  options.method ||= 'GET';
  options.timeout ||= 30000;
  options.baseURL = urls.api;
  options.withCredentials = true;
  options.headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${cookies.get('jwt_token')}`,
  };
  console.debug(options);

  return axios.request({ url, ...options });
}

export default axiosRequest;
