import { useDispatch } from 'react-redux';
import { currentComponent, hideModal, showModal } from '../../../../store/modal';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import capitalize from '../../../../utils/capitalize';
import { handleTxApprovalRejection } from '../../../../store/transactions';

const actionApprove = 'approve';
const actionReject = 'reject';

function Action({ action, tx }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleSubmit() {
    const load = toast.loading('Submitting');
    handleTxApprovalRejection(action, tx.txId)
      .then(res => {
        dispatch(hideModal());
        toast.success(
          `Transaction ${tx.txId} ${action === actionApprove ? 'approved' : 'rejected'}`,
        );
        navigate('/dashboard/withdrawals');
      })
      .catch(e => toast.error(e))
      .finally(() => toast.dismiss(load));
  }

  return (
    <div className="bg-black bg-opacity-40 rounded-sm text-white p-8 space-y-5">
      <h1 className="text-xl">{capitalize(action)} Withdrawal</h1>
      <table className="text-xs lg:text-sm">
        <tbody>
          <tr>
            <td className="opacity-60 pr-2">Amount</td>
            <td>
              {Number(tx.amount)} {tx.asset.toUpperCase()} ({capitalize(tx.network)})
            </td>
          </tr>
          <tr>
            <td className="opacity-60 pr-2">Transaction ID</td>
            <td>{tx.txId}</td>
          </tr>
          <tr>
            <td className="opacity-60 pr-2">Wallet Owner</td>
            <td>{tx.owner}</td>
          </tr>
        </tbody>
      </table>
      <p>{`Are you sure you want to ${action} this withdrawal?`}</p>
      <div className="flex space-x-2">
        <button
          className="flex-1 py-2 px-4 rounded-md border border-transparent cursor-pointer hover:border-white bg-green-700 hover:bg-green-600"
          onClick={handleSubmit}
        >
          Confirm
        </button>
        <button
          className="flex-1 py-2 px-4 rounded-md border border-transparent cursor-pointer bg-gray-700 hover:bg-gray-600 hover:border-white"
          onClick={() => dispatch(hideModal())}
        >
          Back
        </button>
      </div>
    </div>
  );
}

export function TxActions({ tx, user }) {
  const dispatch = useDispatch();
  const canApprove = user.email !== tx.requestingManager;

  function showAction(action) {
    dispatch(currentComponent(() => <Action action={action} tx={tx} />));
    dispatch(showModal());
  }

  return (
    <div className="flex justify-evenly space-x-10 text-slate-900">
      <button
        onClick={() => showAction(actionApprove)}
        disabled={!canApprove}
        className={`font-semibold p-3 flex-1 text-center md:text-lg rounded cursor-pointer border border-transparent bg-neutral-200 ${canApprove ? 'hover:bg-neutral-100 hover:border-green-500' : 'opacity-50'}`}
      >
        Approve
      </button>
      <button
        onClick={() => showAction(actionReject)}
        className="font-semibold p-3 flex-1 text-center md:text-lg rounded cursor-pointer border border-transparent bg-neutral-200 hover:bg-neutral-100 hover:border-red-500"
      >
        {user.email === tx.requestingManager ? 'Cancel' : 'Reject'}
      </button>
    </div>
  );
}

export default TxActions;
