import { urls } from '../config';
import axiosRequest from './axios';

/**
 * @description - Get all notifications for a user identified with their Cognito subject (sub).
 */
export async function getNotifications() {
  return axiosRequest(urls.notifications, { method: 'GET' });
}

/**
 * @description - Mark all notifications as seen.
 */
export async function markNotificationsAsSeen() {
  return axiosRequest(`${urls.notifications}/all`, { method: 'PATCH' });
}

/**
 * @description - Get count of all unread notifications for a user identified with their Cognito subject (sub).
 */
export async function unreadCount() {
  return axiosRequest(`${urls.notifications}/count`, { method: 'GET' });
}
