import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useSort from '../../../hooks/useSort';
import usePaginate from '../../../hooks/usePaginate';
import PageNavigation from '../../../utils/PageNavigation';
import { Loading } from '../../../assets/icons/loading';
import { baseCard } from '../../../config/tailwind.classnames';
import DepositHeader from './DepositHeader';
import DepositListItem from './DepositListItem';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

import FilterEntries, { SearchToolbar } from './actions/FilterEntries';
import { getDeposits } from '../../../store/transactions';

function DepositList() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [activeSort, setActiveSort] = useState(null);
  const [apiError, setApiError] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchCategory, setSearchCategory] = useState({ label: 'Wallet ID', key: 'walletId' });
  const [deposits, setDeposits] = useState([]);
  const [filteredDeposits, setFilteredDeposits] = useState([]);

  // Redux
  const { role } = useSelector(state => state.session);

  // Sort and paginate transactions. Allow jumping to pages by page number.
  const { sortedItems, requestSort, sortConfig } = useSort(filteredDeposits);

  const { prev, jump, next, page } = usePaginate(sortedItems, 20);

  const currentPageData = page.data();

  const [displayPageNumber, setDisplayPageNumber] = useState(page.current);

  const [filter, setFilter] = useState({});
  const [organizationVisibility, setOrganizationVisibility] = useState({
    ETANATRUST: true,
    BDACS: true,
  });

  useEffect(() => jump(displayPageNumber), [displayPageNumber, jump]);

  // Get deposits from API, which just queries the database
  useEffect(() => {
    getDeposits()
      .then(d => {
        setDeposits(d || []);
        setFilteredDeposits(d || []);
      })
      .catch(() => setApiError(true))
      .finally(() => setIsLoaded(true));
  }, []);

  const getClassNamesFor = name => {
    if (!sortConfig) return;
    return sortConfig.key === name ? sortConfig.direction : '';
  };

  const sortBy = key => setActiveSort(`${key}--${requestSort(key)}`);

  const showCaret = name => {
    if (!activeSort) return;
    const [key, direction] = activeSort.split('--');
    if (name !== key) return;

    if (direction === 'ascending') return <ChevronUpIcon className="w-3" />;
    if (direction === 'descending') return <ChevronDownIcon className="w-3" />;
  };

  const pageNavigation = (
    <PageNavigation
      prev={prev}
      jump={jump}
      next={next}
      curr={page.current}
      max={page.max}
      displayPageNumber={displayPageNumber}
      setDisplayPageNumber={setDisplayPageNumber}
    />
  );

  return (
    <div className="xl:px-28 mx-auto min-w-fit max-w-[900px]">
      <DepositHeader
        props={{
          deposits,
          organizationVisibility,
          setOrganizationVisibility,
          jump,
        }}
      />
      <SearchToolbar
        setSearchQuery={setSearchQuery}
        setActiveCategory={setSearchCategory}
        activeCategory={searchCategory}
        searchQuery={searchQuery}
        allCategoryOptions={[{ label: 'Origin', key: 'origin' }]}
      />
      <div className={`${baseCard} ${role === 'admins' && 'border-2 !border-green-500'}`}>
        <div className=" space-x-2" align="right">
          <FilterEntries
            filter={filter}
            setFilter={setFilter}
            jump={jump}
            items={deposits}
            organizationVisibility={organizationVisibility}
            searchCategory={searchCategory}
            searchQuery={searchQuery}
            setFilteredItems={setFilteredDeposits}
            itemDateField={'seenAt'}
          />
        </div>
        {currentPageData.length ? (
          <>
            <table className="w-full">
              <thead>
                <tr className="h-8 w-full text-xs text-left leading-none text-white font-semibold px-4">
                  <th></th>
                  <th>
                    <button
                      onClick={() => sortBy('walletId')}
                      className={`${getClassNamesFor('walletId')} flex items-center`}
                    >
                      <span className="pl-4">Wallet ID</span>
                      <span className="pl-2">{showCaret('walletId')}</span>
                    </button>
                  </th>
                  <th>
                    <button
                      onClick={() => sortBy('amount')}
                      className={`${getClassNamesFor('amount')} flex items-center`}
                    >
                      <span className="pl-4">Amount</span>
                      <span className="pl-2">{showCaret('amount')}</span>
                    </button>
                  </th>
                  <th>
                    <button
                      onClick={() => sortBy('seenAt')}
                      className={`${getClassNamesFor('seenAt')} flex items-center`}
                    >
                      <span className="pl-4">Date Seen</span>
                      <span className="pl-2">{showCaret('seenAt')}</span>
                    </button>
                  </th>
                  <th>
                    <span className="pl-4">Origin</span>
                  </th>
                </tr>
              </thead>
              <tbody className="w-full">
                {currentPageData.map((d, index) => (
                  <DepositListItem key={index + d.txHash} data={d} searchQuery={searchQuery} />
                ))}
              </tbody>
            </table>
            <div className="flex w-full items-center text-white text-sm justify-center py-4">
              {page.from} - {page.to} of {sortedItems.length} deposits
            </div>
            {pageNavigation}
          </>
        ) : isLoaded && apiError ? (
          <div className="flex flex-col w-full items-center text-white text-sm justify-center py-4 pt-8">
            Error retrieving deposits.
            <button
              className="inline-flex items-start justify-start px-6 py-4 bg-slate-700 hover:bg-slate-600 focus:outline-none rounded text-white mt-5"
              onClick={() => window.location.reload()}
            >
              Try Again
            </button>
          </div>
        ) : isLoaded ? (
          <div className="flex w-full items-center text-white text-sm justify-center py-4 pt-8">
            No deposits
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
}

export default DepositList;
