import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Select from 'react-select';
import { toast } from 'react-hot-toast';

import { createNewWallet, getWalletAssets } from '../../../../store/wallets';
import { getManagers } from '../../../../store/users';

import { icon } from '../../../../config/tailwind.classnames';
import { base } from '../../../../config/select.styles';
import { NetworkLogo } from '../../../../assets/networks';
import { XMarkIcon } from '@heroicons/react/24/outline';
import capitalize from '../../../../utils/capitalize';

function NewWallet() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const wallets = useSelector(state => state?.wallets);

  const assets = wallets?.assets?.reduce((acc, asset) => {
    if (Array.isArray(asset?.data)) {
      acc?.push(
        ...asset.data.map(assetData => ({
          network: asset?.network,
          id: [asset?.network, assetData?.asset].join('-'),
          ...assetData,
        })),
      );
    }
    return acc;
  }, []);

  const [owner, setOwner] = useState('');
  const [selectedAssets, setSelectedAssets] = useState({
    bitcoin: false,
    ethereum: false,
    avalanche: false,
    polymesh: false,
    stellar: false,
    solana: false,
    ripple: false,
    aptos: false,
    cardano: false,
    polkadot: false,
  });

  const { user, role } = useSelector(state => state.session);
  const _mgrData = useSelector(state => state?.users && state?.users?.managers);

  const [managers, setManagers] = useState({
    added: [user?.email],
    selected: [],
    list: '',
  });

  useEffect(() => {
    dispatch(getWalletAssets({})).catch(e => {
      console.log(e);
      toast.error(`Error getting wallet assets: ${e}`);
    });
  }, [dispatch]);

  useEffect(() => {
    if (user.groups.includes('investors')) {
      dispatch(getManagers());
      return;
    }
    toast.dismiss();
    toast.error('Not authorized to create new wallet as admin.');
    navigate('/dashboard');
  }, [user.groups, dispatch, role, navigate]);

  useEffect(() => {
    if (_mgrData) {
      setManagers(prev => ({
        ...prev,
        list: _mgrData
          .filter(mgr => !managers.added.includes(mgr))
          .map(mgr => ({ label: mgr, value: mgr })),
      }));
    }
  }, [_mgrData, managers.added]);

  const getSelectedAssetIds = () =>
    Object.keys(selectedAssets).filter(key => selectedAssets[key] === true);

  const handleSubmit = async e => {
    e.preventDefault();
    toast.dismiss();
    toast.loading('Working...');

    if (!getSelectedAssetIds().length) {
      toast.dismiss();
      toast.error('There must be an asset selected.');
      return;
    }

    if (managers.added.length < 2) {
      toast.dismiss();
      toast.error('There must be a minimum of two managers.');
      return;
    }

    if (!owner) {
      toast.dismiss();
      toast.error('Please enter an owner name.');
      return;
    }

    if (!user) {
      return;
    }
    const assets = getSelectedAssetIds()?.map(assetId => {
      const [network, asset] = assetId?.split('-');
      return {
        network,
        asset,
      };
    });

    dispatch(
      createNewWallet({
        managers: managers?.added,
        owner,
        orgId: user?.organization,
        assets,
      }),
    )
      .then(res => {
        toast.dismiss();
        if (res && res[0]?.error) {
          toast.error(`${res[0].error}`);
        } else {
          navigate(`/dashboard/wallets/${res.data.walletId}`);
          toast.success('Wallet created successfully!');
        }
      })
      .catch(e => {
        console.error(e);
        toast.dismiss();
        toast.error(`Error creating wallet: ${e}`);
      });
  };

  return (
    <div className="flex justify-center">
      <form
        onSubmit={handleSubmit}
        className="bg-gray-900 p-10 rounded min-w-fit max-w-fit space-y-5 text-gray-200"
      >
        <h1 className="text-3xl font-bold pb-8">Create New Wallet</h1>
        <ManagersList managers={managers} setManagers={setManagers} />
        <AddManagers managers={managers} setManagers={setManagers} />
        <OwnerInput owner={owner} setOwner={setOwner} />
        <AssetInput
          assets={assets}
          selectedAssets={selectedAssets}
          setSelectedAssets={setSelectedAssets}
        />
        <SubmitAndCancelButtons />
      </form>
    </div>
  );
}

function ManagersList({ managers, setManagers }) {
  function removeManager(mgr) {
    setManagers(prev => ({ ...prev, added: prev?.added?.filter(m => m !== mgr) }));
  }

  return (
    <>
      <div className="text-lg">Managers</div>
      <div className="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-1">
        {managers.added.map((mgr, i) => (
          <div className="text-xs flex rounded bg-gray-500 p-2 justify-between" key={i}>
            <span>{mgr}</span>
            <span>
              {i === 0 ? (
                '(You)'
              ) : (
                <XMarkIcon
                  onClick={() => removeManager(mgr)}
                  className="w-4 hover:text-red-500 cursor-pointer"
                />
              )}
            </span>
          </div>
        ))}
      </div>
    </>
  );
}

function AddManagers({ managers, setManagers }) {
  function handleSelectManager(selectedOptions) {
    const selectedManagers = selectedOptions.map(option => option.value);
    if (!selectedManagers.length || !managers.list) {
      toast.error('Manager list has not yet been loaded. Please wait...');
      return;
    }
    setManagers(prev => ({
      ...prev,
      added: [...prev.added, ...selectedManagers],
      selected: [],
    }));
  }

  return (
    <div className="flex space-x-5 items-center">
      <label className="text-sm">Add managers</label>
      <Select
        closeMenuOnSelect={false}
        name="asset"
        onChange={handleSelectManager}
        className="w-[700px] text-sm"
        value={managers?.selected}
        isMulti={true}
        options={managers?.list || []}
        styles={base}
      />
    </div>
  );
}

function OwnerInput({ owner, setOwner }) {
  return (
    <div className="flex space-x-5 items-end">
      <label className="text-lg">Owner</label>
      <div className="flex-1">
        <input
          onChange={e => setOwner(e.target.value)}
          autoComplete="off"
          type="text"
          data-lpignore="true"
          placeholder="owner name"
          value={owner}
          className="text-right flex text-sm bg-transparent text-white p-3 h-10 border-b mr-3 w-full"
        />
      </div>
    </div>
  );
}

function AssetInput({ assets, selectedAssets, setSelectedAssets }) {
  const toggleAsset = asset =>
    setSelectedAssets(prev => ({ ...prev, [asset]: !selectedAssets[asset] }));

  return (
    <div className="flex space-x-5">
      <label className="text-lg">Assets</label>
      <div className="flex-1">
        <div className="grid grid-cols-3 md:grid-cols-4 gap-1">
          {assets?.map((asset, i) => (
            <button
              type="button"
              key={i}
              name={asset.id}
              onClick={() => toggleAsset(asset.id)}
              className={`relative p-3 rounded-md flex items-center justify-evenly ${selectedAssets[asset.id] ? 'bg-green-700 hover:bg-green-600' : 'bg-gray-800 hover:bg-gray-700'}`}
            >
              <div className="max-w-10 max-h-10">
                <NetworkLogo network={asset.network} token={asset.asset} size="s" />
              </div>
              <div>
                <p>{asset.asset.toUpperCase()}</p>
                <p className="text-xs">{capitalize(asset.network)}</p>
              </div>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

function SubmitAndCancelButtons() {
  const navigate = useNavigate();

  return (
    <div className="flex justify-between space-x-4">
      <button
        className={`${icon.green} hover:bg-gradient-to-br hover:from-green-400 hover:to-green-500/75`}
        type="submit"
      >
        Submit
      </button>
      <button
        className={`${icon.grey} text-white`}
        onClick={() => navigate('/dashboard/wallets')}
        type="button"
      >
        Cancel
      </button>
    </div>
  );
}

export default NewWallet;
