import { useEffect, useState, useRef, FormEvent, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import etana from '../../assets/Etana/white.svg';
import validator from 'validator';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { currentComponent, showModal } from '../../store/modal';
import ForgotPassword from './ForgotPassword';
import FirstLogin from './FirstLogin';
import MFA from './MFA';
import Cookies from 'universal-cookie';
import getSessionExpiration from '../../utils/getSessionExpiration';
import { storeUserSession } from '../../store/session';
import { AnyAction } from 'redux';

export function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [forgotPassword, setForgotPassword] = useState(false);
  const [errors, setErrors] = useState<string[] | null>(null);

  const usernameInput = useRef(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cookies = useMemo(() => new Cookies(), []);

  useEffect(() => {
    usernameInput.current?.focus();
    setErrors(null);
    Auth.currentAuthenticatedUser()
      .then(async res => {
        if (!res) {
          console.warn('No authenticated user');
          return;
        }
        if (!cookies.get('jwt_token')) {
          const token = res.getSignInUserSession().getAccessToken().getJwtToken();
          const expiration = await getSessionExpiration();
          cookies.set('jwt_token', token, { path: '/', maxAge: expiration });
        }
        navigate('/dashboard');
      })
      .catch(console.warn);
  }, [cookies, navigate]);

  async function handleLogin(e: FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();
    setErrors(null);

    const errors = [];
    if (!username) {
      errors.push('Please enter your email');
    } else if (!validator.isEmail(username)) {
      errors.push('Please enter a valid email');
    }
    if (!password) {
      errors.push('Please enter your password');
    }
    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    let user: any;
    const loadingMsg = toast.loading('Signing in...');
    try {
      user = await Auth.signIn(username, password);
    } catch (e) {
      toast.error(`Error with login: ${e}`);
      setErrors([e.message]);
      return;
    } finally {
      toast.dismiss(loadingMsg);
    }

    const { challengeName } = user;
    switch (challengeName) {
      case 'NEW_PASSWORD_REQUIRED':
        dispatch(currentComponent(() => <FirstLogin userState={user} />));
        dispatch(showModal());
        setPassword('');
        break;
      case 'MFA_SETUP':
      case 'SOFTWARE_TOKEN_MFA':
        dispatch(currentComponent(() => <MFA userState={user} username={username} />));
        dispatch(showModal());
        setPassword('');
        break;
      default:
        // TODO: correct typing on Redux thunks
        dispatch(storeUserSession(user, null) as unknown as AnyAction).then((res: any) => {
          if (res) navigate('/authenticating');
        });
    }
  }

  if (forgotPassword) {
    return (
      <div className="flex justify-center pt-10">
        <ForgotPassword setForgotPassword={setForgotPassword} />;
      </div>
    );
  }

  return (
    <div className="flex justify-center pt-10">
      <div className="grid p-24 bg-gradient-to-br from-gray-400/20 via-gray-200/25 to-gray-800/60 rounded-md space-y-4">
        <div className="pb-12 opacity-40">
          <img src={etana} alt="Etana Logo" className="max-w-[500px]" />
        </div>
        <ErrorTextBox errors={errors} />
        <form className="grid text-white min-w-fit space-y-5" onSubmit={handleLogin}>
          <div className="flex items-center justify-between">
            <label>Email</label>
            <input
              className="w-64 p-3 text-gray-300 bg-gray-800 rounded"
              name="email"
              type="email"
              ref={usernameInput}
              value={username}
              onChange={e => setUsername(e.target.value)}
            ></input>
          </div>
          <div className="flex items-center justify-between">
            <label>Password</label>
            <input
              className="w-64 p-3 text-gray-300 bg-gray-800 rounded"
              name="password"
              type="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
            ></input>
          </div>
          <button
            className="text-xl bg-green-800 border border-white hover:opacity-90 p-5 rounded-md"
            type="submit"
          >
            Login
          </button>
        </form>
        <div className="flex justify-center pt-5">
          <button
            className="text-xs text-gray-400 hover:text-white"
            onClick={() => setForgotPassword(true)}
          >
            Forgot Password?
          </button>
        </div>
      </div>
    </div>
  );
}

function ErrorTextBox({ errors }: { errors?: string[] }) {
  return (
    <div
      className={`transition-all text-black text-sm bg-red-100 border-red-600 rounded-md ${!errors || errors.length === 0 ? 'h-0' : 'h-auto p-3 border'}`}
    >
      {errors?.map((e, i) => <div key={i}>{e}</div>)}
    </div>
  );
}

export default Login;
