import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { baseCard } from '../../../config/tailwind.classnames';
import usePaginate from '../../../hooks/usePaginate';
import PageNavigation from '../../../utils/PageNavigation';
import { Loading } from '../../../assets/icons/loading';
import WalletListItem from './WalletListItem';
import { getWallets } from '../../../store/wallets';
import Select from 'react-select';
import { base } from '../../../config/select.styles';
import { createSelector } from '@reduxjs/toolkit';
import WalletsHeader from './WalletsHeader';

const WalletList = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [searchCategory, setSearchCategory] = useState({ label: 'Wallet ID', key: 'walletId' });
  const [searchQuery, setSearchQuery] = useState('');
  const [activeWallets, setActiveWallets] = useState([]);

  const dispatch = useDispatch();
  const errorRef = useRef(null);
  const { user } = useSelector(state => state.session);

  const walletState = createSelector(
    [state => state.wallets, state => Object.values(state)],
    (_, state) => state,
  );
  const wallets = walletState(useSelector(state => state.wallets));

  // Get wallets and store in Redux
  useEffect(() => {
    const controller = new AbortController();
    dispatch(getWallets(controller.signal))
      .then(res => {
        if (Array.isArray(res)) {
          const sortedData = res.sort((a, b) => b.active - a.active);
          setActiveWallets(sortedData);
        }
      })
      .then(() => clearTimeout(errorRef.current))
      .catch(err => setApiError(err))
      .finally(() => setIsLoaded(true));

    return () => {
      controller.abort();
      setSearchQuery('');
      setIsLoaded(false);
      setApiError(null);
    };
  }, [dispatch]);

  if (!isLoaded || !wallets) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col items-center">
      <div className="space-y-5">
        <WalletsHeader user={user} />
        <SearchBar
          searchCategory={searchCategory}
          setSearchCategory={setSearchCategory}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
        <WalletsTable
          user={user}
          searchCategory={searchCategory}
          searchQuery={searchQuery}
          apiErrors={apiError}
          wallets={activeWallets}
        />
      </div>
    </div>
  );
};

function SearchBar({ searchCategory, setSearchCategory, searchQuery, setSearchQuery }) {
  const handleSelect = e => {
    setSearchQuery('');
    setSearchCategory(e);
  };

  return (
    <form onSubmit={e => e.preventDefault()} className="w-full text-sm flex space-x-3">
      <span className="flex min-w-fit items-center">Search by</span>
      <Select
        className="w-36 hover:bg-gray-600"
        defaultValue={{ label: 'Wallet ID', name: 'Wallet ID' }}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        options={[
          { label: 'Wallet ID', key: 'walletId' },
          { label: 'Owner', key: 'owner' },
          // { label: 'Managers', key: 'managers' }, // TODO
        ]}
        placeholder="select prefix"
        onChange={handleSelect}
        styles={base}
      />
      <input
        className="flex-1 bg-transparent border-b p-1 focus:outline-none"
        placeholder={searchCategory.label}
        value={searchQuery}
        onChange={e => setSearchQuery(e.target.value)}
      />
    </form>
  );
}

function WalletsTable({ wallets, apiErrors, user, searchQuery, searchCategory }) {
  const [searchData, setSearchData] = useState(null);
  const { prev, jump, next, page } = usePaginate(searchQuery ? searchData : wallets || [], 20);
  const [displayPageNumber, setDisplayPageNumber] = useState(page.current);

  const isSuperAdmin = user?.groups.includes('superadmins');
  const isAdmin = user?.groups.includes('admins');

  useEffect(() => jump(displayPageNumber), [displayPageNumber, jump]);

  useEffect(() => {
    if (!wallets || !searchQuery) {
      return;
    }
    // Filter given a query and a category
    const arr = wallets?.filter(wallet => {
      if (!wallet) return false;

      // Searching by whatever data is set as the search category
      const keyValue = wallet[searchCategory.key];

      let category;
      if (typeof keyValue === 'string') category = keyValue.toLowerCase();
      // Managers are in an array and must be joined into a string to be searched the same way as the other data
      if (typeof keyValue === 'object') category = keyValue.join(', ').toLowerCase();
      const query = searchQuery?.toLowerCase();

      if (!category?.includes(query)) return false;
      if (typeof keyValue === 'object' && query.length) {
        const idx = keyValue.findIndex(manager => manager.includes(query));
        if (idx > 0) {
          // if the queried item is not at the front, splice it (remove it) and place in the front
          const managerInQuery = keyValue.splice(idx, 1);
          keyValue.unshift(managerInQuery);
        }
      }

      return true;
    });
    // Jump to first page so a user isn't stuck on a page that no longer exists
    jump(1);
    setSearchData(arr);
  }, [jump, searchCategory.key, wallets, searchQuery, setSearchData]);

  if (wallets?.length > 0) {
    return (
      <div className={`${baseCard} ${(isAdmin || isSuperAdmin) && 'border-2 border-green-500'}`}>
        <>
          <table>
            <thead>
              <tr className="text-sm text-left">
                <th>Wallet ID</th>
                <th>Owner</th>
                <th>Managers</th>
                <th>Assets</th>
              </tr>
            </thead>
            <tbody>
              {page.data()?.map((wallet, i) => (
                <WalletListItem
                  key={i}
                  data={wallet}
                  category={searchCategory}
                  query={searchQuery}
                />
              ))}
            </tbody>
          </table>
          {searchQuery && !searchData?.length ? (
            <div className="flex flex-1 items-center text-white text-sm justify-center py-4">
              No wallets matched your search criteria.
            </div>
          ) : (
            <>
              <div className="flex flex-1 items-center text-white text-sm justify-center py-4">
                {page.from} - {page.to} of {searchQuery ? searchData?.length : wallets?.length}{' '}
                wallets
              </div>
              <PageNavigation
                prev={prev}
                jump={jump}
                next={next}
                curr={page.current}
                max={page.max}
                displayPageNumber={displayPageNumber}
                setDisplayPageNumber={setDisplayPageNumber}
              />
            </>
          )}
        </>
      </div>
    );
  }

  if (apiErrors) {
    return (
      <div className="flex flex-col w-full items-center text-white text-sm justify-center py-4 pt-8">
        Error retrieving wallets: Request timed out.
        <button
          className="inline-flex items-start justify-start px-6 py-4 bg-slate-700 hover:bg-slate-600 focus:outline-none rounded text-white mt-5"
          onClick={() => window.location.reload()}
        >
          Try Again
        </button>
      </div>
    );
  }

  return (
    <div className="flex flex-1 items-center text-white text-sm justify-center py-4 pt-8">
      No wallets
    </div>
  );
}

export default WalletList;
