type Urls = {
  baseUrl: string;
  api: string;
  notifications: string;
};

export enum enuEnvironment {
  local = 'local',
  uat = 'uat',
  production = 'production',
}

export const isMainnet = () => {
  return process.env.REACT_APP_ENVIRONMENT === enuEnvironment.production;
};

function baseUrlFromEnvironment(): string {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case enuEnvironment.local:
      return 'http://localhost:3030';
    case enuEnvironment.uat:
      return 'https://api.etanadigital.net';
    case enuEnvironment.production:
      return 'https://api.etanadigital.io';
    default:
      return 'https://api.etanadigital.io';
  }
}

function environmentUrls(): Urls {
  const baseUrl = baseUrlFromEnvironment();
  const prefix = '/v1/dashboard';
  return {
    baseUrl,
    api: `${baseUrl}${prefix}`,
    notifications: `${baseUrl}${prefix}/notifications`,
  };
}

export const urls = environmentUrls();

// TODO: deprecate
export const organizations: { id: string; name: string }[] = [
  { id: 'ETANATRUST', name: 'ETANA TRUST' },
  { id: 'BDACS', name: 'BDACS' },
];
