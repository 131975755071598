import { AxiosError, AxiosResponse, GenericAbortSignal } from 'axios';

import {
  DatabaseUser,
  UserApi,
  RequestUserUpdateRequest,
} from '../sdk';

import { config, handleApiRequest, getBaseOptions } from './axios';
import { GET_USERS, GET_MANAGERS } from './constants';

// ACTION CREATORS
const userList = (data: DatabaseUser[]) => ({ type: GET_USERS, data });
const managerList = (data: DatabaseUser[]) => ({ type: GET_MANAGERS, data });

export const getCurrentUser = async () =>
  handleApiRequest(() =>
    new UserApi(config).v1DashboardUserCurrentGet({ ...getBaseOptions() })
  );

export const updateUser = async (req: RequestUserUpdateRequest) =>
  handleApiRequest(() =>
    new UserApi(config).v1DashboardUserPatch(req, { ...getBaseOptions() })
  );

export const manageUserApiKey = async (deleteAction: boolean) =>
  handleApiRequest(() =>
    new UserApi(config).v1DashboardUserApikeyPatch(deleteAction ? 'true' : undefined, undefined, { ...getBaseOptions() })
  );

export const getUsers = (signal: GenericAbortSignal) => async (dispatch: any) => {
  const output = await handleApiRequest(() => new UserApi(config).v1DashboardUserGet({ ...getBaseOptions(), signal }));
  await dispatch(userList(output));
  return output;
};

export const getManagers = () => async (dispatch: any) => {
  const res = await new UserApi(config).v1DashboardUserGet({ ...getBaseOptions() });
  const output = res.data;
  const managers = output.filter(user => user.groups.includes('investors'));
  dispatch(managerList(managers));
  return managers;
};

// REDUCER
const userReducer = (state = {}, action: { type: string, data: any, walletId: any, network: string }) => {
  const newState = { ...state } as any;
  switch (action.type) {
    case GET_USERS: {
      if (action.data) action.data.map((user: DatabaseUser) => (newState[user.email] = user));
      return newState;
    }
    case GET_MANAGERS: {
      newState.managers = [];
      if (action.data) action.data.map((user: DatabaseUser) => newState.managers.push(user.email));
      return newState;
    }
    default:
      return state;
  }
};

export default userReducer;
