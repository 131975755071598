import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal } from '../../../../store/modal';
import { addAsset, getWalletAssets, getWalletById, getBalances } from '../../../../store/wallets';
import { NetworkLogo } from '../../../../assets/networks';
import { getAvailableAssets } from '../../../../utils/assets';
import capitalize from '../../../../utils/capitalize';

const AddAsset = ({ id }) => {
  const [availableAssets, setAvailableAssets] = useState([]);
  const [selectedAssets, setSelectedAssets] = useState([]);

  const wallet = useSelector(state => state?.wallets && state.wallets[id]);
  const wallets = useSelector(state => state.wallets);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!wallets.assets) {
      return;
    }
    const controller = new AbortController();
    dispatch(getWalletById(controller.signal, id))
      .then(res => {
        res && res.accounts && setAvailableAssets(getAvailableAssets(wallets.assets, res.accounts));
      })
      .catch(e => console.log(e));
    return () => controller.abort();
  }, [dispatch, id, wallets.assets]);

  useEffect(() => {
    dispatch(getWalletAssets({})).catch(e => {
      console.log(e);
      toast.error(`Error getting wallet assets: ${e}`);
    });
  }, [dispatch]);

  useEffect(() => {
    if (!wallet.active) {
      toast.dismiss();
      toast.error('Wallet has been disabled. Contact an administrator.');
      dispatch(hideModal());
    }
  }, [wallet, dispatch]);

  const handleSubmit = async e => {
    e.preventDefault();
    if (selectedAssets.length === 0) {
      toast.error('Please choose at least one asset to add.');
      return;
    }
    const load = toast.loading('Adding asset(s)');
    dispatch(
      addAsset({
        walletId: id,
        assets: selectedAssets.map(asset => ({
          network: asset.network,
          asset: asset.asset,
        })),
      }),
    )
      .then(async () => {
        const controller = new AbortController();
        dispatch(getBalances(controller.signal, { walletId: id }));
      })
      .then(() => toast.success('Asset(s) added successfully'))
      .then(() => dispatch(hideModal()))
      .catch(err => toast.error(err?.message))
      .finally(() => toast.dismiss(load));
  };

  return (
    <div className="text-white">
      <div className="bg-gray-900 bg-opacity-90 text-xs rounded-sm p-10 space-y-4">
        <h1 className="text-3xl">Add Asset</h1>
        <table>
          <tbody>
            <tr>
              <td className="opacity-60 pr-2">Wallet ID</td>
              <td>{id}</td>
            </tr>
            <tr>
              <td className="opacity-60 pr-2">Owner</td>
              <td>{wallet?.owner}</td>
            </tr>
          </tbody>
        </table>
        <form className="text-sm space-y-4" onSubmit={handleSubmit}>
          <p>Which asset(s) would you like to add to this wallet?</p>
          <div className="grid grid-cols-3 xl:grid-cols-4 gap-2">
            {availableAssets?.map(assetData => (
              <AssetButton
                key={assetData.id}
                assetData={assetData}
                selectedAssets={selectedAssets}
                setSelectedAssets={setSelectedAssets}
              />
            ))}
          </div>
          <div className="flex gap-2 font-semibold text-md">
            <button className="p-4 flex-1 bg-green-700 hover:bg-green-600 rounded" type="submit">
              Confirm
            </button>
            <button
              className="p-4 flex-1 bg-gray-700 hover:bg-gray-600 rounded"
              onClick={() => dispatch(hideModal())}
              type="button"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

function AssetButton({ assetData, selectedAssets, setSelectedAssets }) {
  function handleAssetClick(assetData) {
    setSelectedAssets(prevSelected => {
      if (prevSelected.some(asset => asset.id === assetData.id)) {
        return prevSelected.filter(asset => asset.id !== assetData.id);
      }
      return [...prevSelected, assetData];
    });
  }

  return (
    <div
      key={assetData.id}
      className={`flex justify-evenly space-x-4 text-center p-5 cursor-pointer rounded-sm ${
        selectedAssets.some(asset => asset.id === assetData.id)
          ? 'bg-blue-800 hover:bg-blue-600'
          : 'bg-slate-800 hover:bg-slate-600'
      }`}
      onClick={() => handleAssetClick(assetData)}
    >
      <div className="max-w-6 lg:max-w-10 flex flex-col justify-center">
        <NetworkLogo network={assetData.network} token={assetData.asset} size="s" />
      </div>
      <div className="flex flex-col">
        <span className="text-sm">{assetData?.asset?.toUpperCase()}</span>
        <span className="opacity-80 text-xs">{capitalize(assetData?.network)}</span>
      </div>
    </div>
  );
}

export default AddAsset;
