import { configureStore, combineReducers } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import { REMOVE_USER } from './constants';
import modalReducer from './modal';
import sessionReducer from './session';
import userReducer from './users';
import transactionReducer from './transactions';
import walletReducer from './wallets';

const combinedReducers = combineReducers({
  modal: modalReducer,
  session: sessionReducer,
  users: userReducer,
  transactions: transactionReducer,
  wallets: walletReducer,
});

const rootReducer = (state, action) => {
  if (action.type === REMOVE_USER) {
    const newState = { ...state };
    newState.session = { user: null, role: null };
    newState.users = {};
    newState.transactions = {};
    newState.wallets = {};
    return newState;
  }
  return combinedReducers(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk],
});

export default store;
