import { useEffect, useRef, useState } from 'react';
import { baseCard } from '../../config/tailwind.classnames';
import { icon } from '../../config/tailwind.classnames';
import axiosRequest from '../../store/axios';
import { Loading } from '../../assets/icons/loading';
import { urls } from '../../config';
import { toast } from 'react-hot-toast';
import { useUser } from '../../hooks/useUser';

/**
 * @description - Get transactions by calling the API. Just return the data, or throw if the API call errors.
 * @throws
 * @returns {Promise<Object>} - total balances over each organization
 */
async function getSummary() {
  const url = `${urls.api}/organization/summary`;
  const controller = new AbortController();
  const opts = { signal: controller.signal };
  const res = await axiosRequest(url, opts);
  if (!res || !res.status) throw new Error('No response');
  if (res.status >= 300) throw new Error(`Error status ${res.status}`);
  return res.data;
}

/**
 * @description - Sum over deposits & withdrawals to get an organization's overall balance (up-to-date within a few minutes)
 * @returns {JSX.Element}
 * @constructor
 */
const OrganizationSummary = () => {
  const summary = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [longLoad, setLongLoad] = useState(false);

  useEffect(() => {
    getSummary()
      .then(s => (summary.current = s))
      .catch(e => toast.error(`Failed to get organization summary: ${e}`))
      .finally(() => setLoaded(true));
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!loaded) setLongLoad(true);
    }, 300);
    return () => clearTimeout(timer);
  }, [loaded]);

  if (!loaded) {
    return longLoad ? <Loading /> : null;
  }

  return (
    <div className="w-3/5 max-w-sm text-sm md:text-lg">
      {summary.current &&
        Object.entries(summary.current).map(([token, organizationWideBalance]) => (
          <div className="grid grid-cols-2" key={token}>
            <span className="text-gray-300 text-left min-w-fit">{token.toUpperCase()}:</span>
            <span>{organizationWideBalance}</span>
          </div>
        ))}
    </div>
  );
};

function OrganizationSummaryCard({ organization }) {
  return (
    <div className="p-5 bg-slate-800 bg-gradient-to-br w-1/2 rounded-md">
      <div className="text-sm md:text-lg">Assets custodied by {organization}</div>
      <OrganizationSummary />
    </div>
  );
}

export default function Home() {
  const {
    user: { email, givenName, familyName, orgId, groups },
  } = useUser();

  const displayName = useRef(
    [givenName?.trim(), familyName?.trim()].filter(i => !!i).join(' ') || email?.split('@')[0],
  );

  function userGroupIcon(groups) {
    if (groups.includes('superadmins')) {
      return <div className={icon.user.super}>SUPER ADMIN</div>;
    }
    if (groups.includes('admins')) {
      return <div className={icon.user.admin}>ADMIN</div>;
    }
    if (groups.includes('investors')) {
      return <div className={icon.user.investor}>INVESTOR</div>;
    }
    return null;
  }

  return (
    <div className="pt-3">
      <div className={`${baseCard} !p-10 !pr-0 min-h-[500px]`}>
        <div className="text-2xl pb-4">
          {/* Dashboard home text */}
          <div className="flex justify-between">
            <div className="flex">
              <h1>{orgId}</h1>
              <span className="pl-2 opacity-60">DASHBOARD</span>
            </div>
            {userGroupIcon(groups)}
          </div>
          <div className="text-lg">Welcome back {displayName.current}!</div>
          <div className="!text-sm opacity-60">
            Select category in the sidebar menu to continue.
          </div>
        </div>
        <OrganizationSummaryCard organization={orgId} />
      </div>
    </div>
  );
}
