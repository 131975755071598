import {
  AssetApi,
  BalanceApi,
  DatabaseWallet,
  ManagerApi,
  OpenapiBlockchainAssetsData,
  OpenapiRoutesBalanceOnWalletResponse,
  RequestAddAssetRequest,
  RequestCreateWallet,
  RequestHideAssetRequest,
  WalletApi,
} from '../sdk';

import {
  ADD_MGR_TO_WALLET,
  ASSETS_WALLET,
  CREATE_WALLET,
  DISABLE_ACCOUNT_IN_WALLET,
  DISABLE_WALLET,
  ENABLE_ACCOUNT_IN_WALLET,
  ENABLE_WALLET,
  GET_BALANCES,
  GET_ONE_WALLET,
  GET_WALLETS,
  REM_MGR_FM_WALLET,
} from './constants';

import { config, getBaseOptions } from './axios';

const setBalances = (data: OpenapiRoutesBalanceOnWalletResponse[], walletId: DatabaseWallet) => ({
  type: GET_BALANCES,
  data,
  walletId,
});
export const setOneWallet = (data: DatabaseWallet) => ({ type: GET_ONE_WALLET, data });
const addManagerToWallet = (walletId: string, managers: string[]) => ({
  type: ADD_MGR_TO_WALLET,
  walletId,
  managers,
});
const removeManagerFromWallet = (walletId: string, managers: string[]) => ({
  type: REM_MGR_FM_WALLET,
  walletId,
  managers,
});
const setEnableWallet = (walletId: string) => ({ type: ENABLE_WALLET, walletId });
const setDisableWallet = (walletId: string) => ({ type: DISABLE_WALLET, walletId });
const newWallet = (data: RequestCreateWallet) => ({ type: CREATE_WALLET, data });
const setWalletAssets = (data: OpenapiBlockchainAssetsData[]) => ({ type: ASSETS_WALLET, data });
const setWallets = (data: DatabaseWallet[]) => ({ type: GET_WALLETS, data });

export const getBalances = (signal: any, id: DatabaseWallet) => async (dispatch: any) => {
  try {
    const res = await new BalanceApi(config).v1DashboardWalletsBalancesIdPost(id.walletId, {
      signal,
      ...getBaseOptions(),
    });
    const output = res.data.data.reduce((acc, bal) => {
      acc.push({
        asset: bal.asset.toLowerCase(),
        network: bal.network,
        balance: bal.balance,
      });
      return acc;
    }, []);
    await dispatch(setBalances(output, id));
    return output;
  } catch (e) {
    return { error: `An error occurred while fetching balances: ${e}` };
  }
};

export const addAsset = (req: RequestAddAssetRequest) => async (dispatch: any) => {
  try {
    const res = await new AssetApi(config).v1DashboardWalletsAssetsAddPost(req, {
      ...getBaseOptions(),
    });
    const output = res.data;
    await dispatch(setOneWallet(output));
    return output;
  } catch (e) {
    return { error: `An error occurred while adding assets: ${e}` };
  }
};

export const hideAsset = (req: RequestHideAssetRequest) => async (dispatch: any) => {
  try {
    const res = await new AssetApi(config).v1DashboardWalletsAssetsHidePost(req, {
      ...getBaseOptions(),
    });
    const output = res.data;
    await dispatch(setOneWallet(output));
    return output;
  } catch (e) {
    return { error: `An error occurred while adding assets: ${e}` };
  }
};

export const getWalletField = async (id: string, field: string) => {
  const res = await new WalletApi(config).v1DashboardWalletsIdIdFieldGet(id, field, {
    ...getBaseOptions(),
  });
  return res.data;
};

export const getWalletById = (signal: any, id: string) => async (dispatch: any) => {
  const res = await new WalletApi(config).v1DashboardWalletsIdIdGet(id, {
    signal,
    ...getBaseOptions(),
  });
  const output = res.data;
  await dispatch(setOneWallet(output));
  return output;
};

export const addManagerToExistingWallet =
  (walletId: string, managers: string[]) => async (dispatch: any) => {
    const res = await new ManagerApi(config).v1DashboardWalletsManagersPost(
      {
        managers,
        walletId,
      },
      { ...getBaseOptions() },
    );
    const output = res.data;
    await dispatch(addManagerToWallet(walletId, managers));
    return output;
  };

export const removeManagerFromExistingWallet =
  (walletId: string, managers: string[]) => async (dispatch: any) => {
    const res = await new ManagerApi(config).v1DashboardWalletsManagersDelete(
      {
        managers,
        walletId,
      },
      { ...getBaseOptions() },
    );
    const output = res.data;
    await dispatch(removeManagerFromWallet(walletId, managers));
    return output;
  };

export const enableDisableWallet =
  (walletId: string, action: 'enable' | 'disable') => async (dispatch: any) => {
    const res = await new WalletApi(config).v1DashboardAdminWalletsWalletIdActionPost(
      walletId,
      action,
      { ...getBaseOptions() },
    );
    const output = res.data;
    switch (action) {
      case 'enable':
        await dispatch(setEnableWallet(walletId));
        break;
      case 'disable':
        await dispatch(setDisableWallet(walletId));
        break;
    }

    return output;
  };

export const createNewWallet = (data: RequestCreateWallet) => async (dispatch: any) => {
  const res = await new WalletApi(config).v1DashboardWalletsPost(data, {
    ...getBaseOptions(),
  });
  const output = res.data;
  await dispatch(newWallet(res.data));
  return output;
};

export const getWalletAssets = () => async (dispatch: any) => {
  const res = await new AssetApi(config).v1DashboardWalletsAssetsGet({ ...getBaseOptions() });
  const output = res.data;
  return dispatch(setWalletAssets(output));
};

export const getWallets = (signal: any) => async (dispatch: any) => {
  const res = await new WalletApi(config).v1DashboardWalletsGet({ ...getBaseOptions(), signal });
  const output = res.data;
  await dispatch(setWallets(output));
  return output;
};

const walletReducer = (
  state = {},
  action: { type: string; data: any; walletId: any; network: string },
) => {
  const newState = { ...state } as any;
  switch (action.type) {
    case CREATE_WALLET: {
      if (action.data) newState[action.data.walletId] = action.data;
      return newState;
    }
    case GET_WALLETS: {
      if (action.data)
        action.data.forEach((wallet: DatabaseWallet) => (newState[wallet.walletId] = wallet));
      return newState;
    }
    case ASSETS_WALLET:
      if (action.data) newState['assets'] = action.data;
      return newState;
    case GET_ONE_WALLET: {
      let bal;
      if (action.data) {
        if (newState[action.data.walletId] && newState[action.data.walletId].balances)
          bal = newState[action.data.walletId].balances;

        newState[action.data.walletId] = action.data;

        if (bal && newState[action.data.walletId]) {
          newState[action.data.walletId].balances = bal;
        }
      }
      return newState;
    }
    case GET_BALANCES: {
      if (action.data && action.walletId.walletId)
        newState[action.walletId.walletId].balances = action.data;
      return newState;
    }
    case ADD_MGR_TO_WALLET: {
      if (action.data && newState[action.data.walletId]) {
        newState[action.data.walletId].managers.push(action.data.managers);
      }
      return newState;
    }
    case REM_MGR_FM_WALLET: {
      if (action.data && newState[action.data.walletId]) {
        const idx = newState[action.data.walletId].managers.indexOf(action.data.managers);
        newState[action.data.walletId].managers.splice(idx, 1);
      }
      return newState;
    }
    case ENABLE_WALLET: {
      if (action.walletId && newState[action.walletId]) {
        newState[action.walletId].active = true;
      }
      return newState;
    }
    case DISABLE_WALLET: {
      if (action.walletId && newState[action.walletId]) {
        newState[action.walletId].active = false;
      }
      return newState;
    }
    case ENABLE_ACCOUNT_IN_WALLET: {
      if (action.walletId && action.network && newState[action.walletId]) {
        newState[action.walletId].accounts[action.network].active = true;
      }
      return newState;
    }
    case DISABLE_ACCOUNT_IN_WALLET: {
      if (action.walletId && action.network && newState[action.walletId]) {
        newState[action.walletId].accounts[action.network].active = false;
      }
      return newState;
    }
    default:
      return state;
  }
};

export default walletReducer;
