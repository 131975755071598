import { useDispatch } from 'react-redux';
import { currentComponent, hideModal, showModal } from '../../../store/modal';
import DepositCard from './DepositCard';
import { AssetAmount } from '../../../assets/AssetAmount';
import { TransactionCard } from '../transactions/TxCard';
import { ArrowUpRightIcon, ArrowDownLeftIcon } from '@heroicons/react/24/outline';

export function HistoryListItem({ data, searchQuery }: any) {
  const dispatch = useDispatch();
  const viewDetail = (data: any) => {
    dispatch(
      currentComponent(() => {
        if (data.type === 'DEPOSIT') {
          return <DepositCard deposit={data} />;
        } else {
          return <TransactionCard transaction={data} onClose={() => dispatch(hideModal())} />;
        }
      }),
    );
    dispatch(showModal());
  };
  let address = data.address ?? data.originAddresses[0];
  if (data.originAddresses?.length > 1) {
    address += ` and ${data.originAddresses.length - 1} more`;
  }

  return (
    <tr
      onClick={() => viewDetail(data)}
      className="text-xs text-white cursor-pointer hover:bg-[#FFFFFF10] border-b border-t border-dotted border-gray-400"
    >
      <td
        className={`flex py-1 items-center space-x-2 ${data.type === 'WITHDRAW' ? 'text-red-200' : ''}`}
      >
        {data.type === 'DEPOSIT' ? (
          <ArrowDownLeftIcon className="w-5" />
        ) : (
          <ArrowUpRightIcon className="w-5" />
        )}
        <AssetAmount
          asset={data.asset}
          network={data.network}
          amount={data.amount}
          type={data.type}
        />
      </td>
      <td>{new Date(data.seenAt).toLocaleString()}</td>
      {data.type === 'DEPOSIT' ? (
        <td className="capitalize">{data.success ? ' Success' : 'Failure'}</td>
      ) : (
        <td className="capitalize">{data.status}</td>
      )}
    </tr>
  );
}
