import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useSort from '../../../hooks/useSort';
import usePaginate from '../../../hooks/usePaginate';
import PageNavigation from '../../../utils/PageNavigation';
import { Loading } from '../../../assets/icons/loading';
import { baseCard } from '../../../config/tailwind.classnames';

import TransactionsHeader from './TransactionsHeader';
import TransactionListItem from './TransactionListItem';
import { ChevronDownIcon, ChevronUpIcon, ArrowPathIcon } from '@heroicons/react/24/outline';
import { FilterEntries, SearchToolbar } from '../deposits/actions/FilterEntries';
import { toast } from 'react-hot-toast';
import { getTransactions } from '../../../store/transactions';

const FilterByStatus = ({ filter, setFilter }) => {
  const selectors = 'w-full rounded text-white text-sm h-8 mr-2';
  const labelText = 'flex text-white items-center text-sm';
  const handleChange = e => {
    const { name, checked } = e.target;
    setFilter(prev => ({
      ...prev,
      approvalStatus: { ...prev.approvalStatus, [name]: checked },
    }));
  };
  return (
    <div className="flex flex-col w-full">
      <div className="mt-5 mb-2 text-sm font-bold text-left">Approval Status</div>
      <div className="flex justify-between w-full">
        <label className={labelText}>
          <input
            type="checkbox"
            className={selectors}
            name="approved"
            onChange={handleChange}
            checked={filter.approvalStatus.approved}
          />
          Approved
        </label>
        <label className={labelText}>
          <input
            type="checkbox"
            className={selectors}
            name="pending"
            onChange={handleChange}
            checked={filter.approvalStatus.pending}
          />
          Pending
        </label>
        <label className={labelText}>
          <input
            type="checkbox"
            className={selectors}
            name="rejected"
            onChange={handleChange}
            checked={filter.approvalStatus.rejected}
          />
          Rejected
        </label>
      </div>
    </div>
  );
};

function TransactionList() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [activeSort, setActiveSort] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [apiError, setApiError] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchCategory, setSearchCategory] = useState({ label: 'Wallet ID', key: 'walletId' });

  // Redux
  const { role } = useSelector(state => state.session);

  // Sort and paginate transactions. Allow jumping to pages by page number.
  const { sortedItems, requestSort, sortConfig } = useSort(filteredTransactions);
  const { prev, jump, next, page } = usePaginate(sortedItems, 20);
  const [displayPageNumber, setDisplayPageNumber] = useState(page.current);

  const [filter, setFilter] = useState({
    assets: {},
    approvalStatus: {},
  });
  const [organizationVisibility, setOrganizationVisibility] = useState({
    ETANATRUST: true,
    BDACS: true,
  });

  function refresh() {
    getTransactions()
      .then(txs => setTransactions(txs || []))
      .catch(() => setApiError(true))
      .finally(() => setIsLoaded(true));
  }

  useEffect(() => jump(displayPageNumber), [displayPageNumber, jump]);

  // Get transactions from API, which just queries the database
  useEffect(() => refresh(), []);

  const customFilterByItem = item => {
    const anyApprovalStatusTrue = Object.values(filter.approvalStatus).some(
      value => value === true,
    );

    let passesApprovalStatusFilter = true;

    // Apply approval status filter if any approval status filter is enabled
    if (anyApprovalStatusTrue) {
      passesApprovalStatusFilter = filter.approvalStatus[item.approval.status];
    }

    return passesApprovalStatusFilter;
  };

  const getClassNamesFor = name => {
    if (!sortConfig) return;
    return sortConfig.key === name ? sortConfig.direction : '';
  };

  const sortBy = key => setActiveSort(`${key}--${requestSort(key)}`);

  const showCaret = name => {
    if (!activeSort) return;
    const [key, direction] = activeSort.split('--');
    if (name !== key) return;

    if (direction === 'ascending') return <ChevronUpIcon className="w-3" />;
    if (direction === 'descending') return <ChevronDownIcon className="w-3" />;
  };

  const pageNavigation = (
    <PageNavigation
      prev={prev}
      jump={jump}
      next={next}
      curr={page.current}
      max={page.max}
      displayPageNumber={displayPageNumber}
      setDisplayPageNumber={setDisplayPageNumber}
    />
  );

  function TableHeaderItem({ name, key }) {
    return (
      <th className="py-2">
        <button
          onClick={() => sortBy(key)}
          className={`${getClassNamesFor(key)} flex items-center space-x-2`}
        >
          <span>{name}</span>
          <span>{showCaret(key)}</span>
        </button>
      </th>
    );
  }

  return (
    <div className="flex justify-center">
      <div>
        <TransactionsHeader
          transactions={transactions}
          organizationVisibility={organizationVisibility}
          setOrganizationVisibility={setOrganizationVisibility}
        />
        <SearchToolbar
          setSearchQuery={setSearchQuery}
          setActiveCategory={setSearchCategory}
          activeCategory={searchCategory}
          searchQuery={searchQuery}
          allCategoryOptions={[{ label: 'Transaction ID', key: 'txId' }]}
        />
        <div className={`${baseCard} ${role === 'admins' && 'border-2 !border-green-500'}`}>
          <div className="flex space-x-2 justify-end text-xs">
            <button
              className="flex border border-white rounded-md space-x-2 items-center px-2 hover:bg-gray-600"
              onClick={() => {
                refresh();
                toast.success('Refreshed transactions');
              }}
            >
              <ArrowPathIcon className="w-5" />
              <span>Refresh</span>
            </button>
            <FilterEntries
              filter={filter}
              setFilter={setFilter}
              jump={jump}
              items={transactions}
              organizationVisibility={organizationVisibility}
              searchCategory={searchCategory}
              searchQuery={searchQuery}
              setFilteredItems={setFilteredTransactions}
              itemDateField={'dateCreated'}
              customFilterByItem={customFilterByItem}
              CustomFilterComponent={<FilterByStatus filter={filter} setFilter={setFilter} />}
            />
          </div>
          {page.data().length ? (
            <>
              {pageNavigation}
              <table className="text-xs text-white space-y-10">
                <thead>
                  <tr className="font-semibold">
                    <th></th>
                    <TableHeaderItem name="Transaction ID" key="txId" />
                    <TableHeaderItem name="Wallet ID" key="walletId" />
                    <TableHeaderItem name="Amount" key="amount" />
                    <TableHeaderItem name="Date Created" key="dateCreated" />
                    <th className="py-2" colSpan="2">
                      Approval
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {page.data().map(tx => (
                    <TransactionListItem key={tx.txId} txData={tx} searchQuery={searchQuery} />
                  ))}
                </tbody>
              </table>
              <div className="flex items-center justify-center w-full py-4 text-sm text-white">
                {page.from} - {page.to} of {sortedItems.length} transactions
              </div>
              {pageNavigation}
            </>
          ) : isLoaded && apiError ? (
            <div className="flex flex-col items-center justify-center w-full py-4 pt-8 text-sm text-white">
              Error retrieving transactions.
              <button
                className="inline-flex items-start justify-start px-6 py-4 mt-5 text-white rounded bg-slate-700 hover:bg-slate-600 focus:outline-none"
                onClick={() => window.location.reload()}
              >
                Try Again
              </button>
            </div>
          ) : isLoaded ? (
            <div className="flex items-center justify-center w-full py-4 pt-8 text-sm text-white">
              No transactions
            </div>
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </div>
  );
}

export default TransactionList;
